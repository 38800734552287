<template>
  <div class="aside-menu">
    <aside-control class="control-menu">
      <template v-slot:title> Меню администратора </template>

      <template v-slot:default>
        <a
          v-for="(button, index) in menuButtons"
          :key="index"
          href="#"
          class="button"
          @click.prevent="setAction(button)"
        >
          {{ button.name }}
        </a>
      </template>
    </aside-control>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AsideControl from '@/components/common/teamplates/ToogleMenu.vue';

export default {
  components: {
    AsideControl,
  },
  emits: ['menuAction'],
  computed: mapGetters('vkRequests', ['getGroupCount']),
  data: () => ({
    showMenu: true,
    showFilter: true,
    logMessage: '',

    menuButtons: [
      { name: 'Добавить в черный список', action: 'add-bl' },
      { name: 'Удалить из черного списка', action: 'del-bl' },
      { name: 'Данные пользователей', action: 'route', route: 'mega-admins' },
      { name: 'Продажа пабликов', action: 'route', route: 'sale-page' },
    ],
  }),
  methods: {
    setAction(button) {
      if (button.action === 'route') {
        this.$router.push({ name: button.route });
        return;
      }

      this.$emit('menuAction', button.action);
    },
  },
};
</script>

<style lang="scss" scoped>
.aside-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.static-info {
  color: #45688e;
  font-weight: 700;
  font-size: 18px;

  margin: 18px 0 20px 0;
}
.control-menu {
  margin: 0 0 20px 0;

  &__info {
    width: 100%;
    height: 200px;

    margin: 20px 0 0 0;
    border-radius: 20px;
    padding: 10px 12px 10px;
    box-sizing: border-box;

    // overflow: auto;

    resize: none;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 35px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: aliceblue;

  border-radius: 10px;
  background: #45688e;

  margin: 15px 10px;
}
</style>
