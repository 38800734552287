<template>
  <div class="table" :draggable="false">
    <div ref="table-header" class="table__header">
      <div class="table__title cell-settings__numbers">
        <img src="/icons/table/hash.svg" class="table__img hash-img" />
      </div>

      <div class="table__title cell-settings__public">
        Название группы
      </div>
    </div>

    <circle-c v-if="checkAnimation" class="animation-container__icon"></circle-c>

    <div v-else class="table__body" :draggable="false">
      <div
        :ref="el => { refsArr[index] = el }"
        v-for="(item, index) in blackList"
        :key="item.id"
        class="table__row"
        @click="checkRow(item, index)"
      >
        <div class="table__title cell-settings__numbers">
          <span class="cell-settings__index">{{
            index + 1
          }}</span>
        </div>

        <div class="table__cell">
          <img :src="item.photo_link" class="table__image">
        </div>

        <div class="table__cell">
           <p class="table__text">
            {{ item.name }}
          </p>
          -
          <p class="table__text">
            {{ item.group_id}} id
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  inject, watch, ref, defineExpose,
} from 'vue';
import { useRouter } from 'vue-router';
import CircleC from '@/components/atom/loader/Circle.vue';

const router = useRouter();
const refsArr = ref([]);
const checkedItems = ref([]);
const blackList = ref([]);
const adminApi = inject('adminApi');

const updateGroups = () => {
  const key = sessionStorage.getItem('crm-key');
  adminApi.value.getBlackList(key).then((resp) => {
    if (resp.error) {
      blackList.value = [];
      router.push({ name: 'auth-admins' });
      return;
    }

    blackList.value = resp;
  });
};

watch(adminApi, () => {
  updateGroups();
});

const findIndexRow = (item, currtArr) => currtArr.findIndex((el) => item.group_id === el.group_id);

const checkRow = (item, index) => {
  const indexOfArr = findIndexRow(item, checkedItems.value);
  if (indexOfArr === -1) {
    checkedItems.value.push(item);
    refsArr.value[index].style.background = '#2555869c';
  } else {
    checkedItems.value.splice(indexOfArr, 1);
    refsArr.value[index].style.background = '';
  }
};

const getCheckedGroups = () => checkedItems.value;
defineExpose({
  getCheckedGroups, updateGroups,
});
</script>

<style lang="scss" scoped>
.settings {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: auto;
  padding: 0 12px;

  flex-basis: 350px;
  max-width: 350px;
  min-width: 350px;

  background-color: #f7f7f7;
  border-left: 1px solid #d8dfea;

  box-sizing: border-box;
}

.cell-settings {
  &__numbers {
    position: relative;
    min-width: 50px;
    width: 50px;
    margin: 0 15px 0 0;
  }

  &__index {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    visibility: visible;
  }
}

.animation-container {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  margin: 0 0 10px 0;

  height: 40px;
  width: 100%;

  background: #383f48;
  border: 1px solid #383f48;
  box-sizing: border-box;
  border-radius: 10px;

  &__icon {
    position: relative;
    width: 125px;
    height: 125px;
    margin: 50px auto 0;
  }

  &__hidden {
    display: none;
  }
}

.hash-img {
  opacity: 1;
}
.test {
  position: fixed;
  bottom: 27px;
  right: calc(41vw - 23%);
  z-index: 15;
}
.link-size {
  font-size: 16px;
}

.table {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;

  // .table__caption

  &__image {
    width: 50px;
    height: 50px;

    margin: 0 5px 0 0;
  }

  // .table__header
  &__header {
    display: flex;
    background: #dee5eb;

    padding: 0 5px;
  }

  // .table__title
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 60px;

    cursor: pointer;
    color: #45688e;
    // background: #fff;
  }

  // .table__body
  &__body {
    display: flex;
    flex-direction: column;

    border-top: 1px solid #dee5eb;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border-bottom: 1px solid #dee5eb;

    padding: 0 8px;
    background: #fff;
  }

  // .table__cell
  &__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;

    padding: 5px 0 5px;
    margin: 1px 10px;
  }

  &__text {
    margin: 0 10px;
  }
}
@media (hover: hover) {
  .cell-settings {
    &__numbers {
      min-width: 50px;
      width: 50px;
      margin: 0 15px 0 0;
    }

    &__public {
      min-width: 250px;
      width: 40%;
      justify-content: flex-start;
    }

    &__coverage {
      min-width: 120px;
      width: calc((100% - 40% - 50px - 60px) / 4);
    }

    &__subscribes {
      min-width: 120px;
      width: calc((100% - 40% - 50px - 60px) / 4);
    }

    &__auditory {
      min-width: 120px;
      width: calc((100% - 40% - 50px - 60px) / 4);
    }

    &__grow {
      min-width: 120px;
      width: calc((100% - 40% - 50px - 60px) / 4);
      cursor: default;
    }

    &__eye {
      min-width: 60px;
      width: 60px;
      opacity: 0;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    @media (max-width: 850px) {
      width: auto;
    }
    // .table__caption

    &__row {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      border-bottom: 1px solid #dee5eb;

      padding: 0 8px;

      &:hover {
        background: #45688e50;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
