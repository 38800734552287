<template>
  <div class="confirm">
    <div class="confirm__back"></div>

    <div class="confirm__main" @click="resetError">
      <p class="confirm__text">Укажите группу для блокировки</p>

      <p class="confirm__text">
        <input
          type="text"
          class="confirm__input"
          placeholder="Введите группу"
          v-model="groupName"
        />
      </p>

      <div ref="error" class="confirm__info" :title="infoMsg">
        {{ infoMsg }}
      </div>

      <div class="confirm__panel">
        <a href="#" class="confirm__button" @click.stop="addGroupInBlackList()">
          {{ okBtn }}
          <span class="confirm__num" v-if="okBtn === 'Error'">
            {{ number }}
          </span>
        </a>

        <a href="#" class="confirm__button" @click="$emit('close', false)"> Отмена </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    method: {
      type: String,
      required: true,
    },
  },
  inject: ['adminApi'],
  emits: ['ok', 'close', 'updateGroups'],
  data: () => ({
    okBtn: 'Подтвердить',
    number: 4,
    groupName: '',
    infoMsg: '',
  }),
  methods: {
    incrimentNumber() {
      this.number -= 1;
      if (this.number > 0) {
        setTimeout(() => {
          this.incrimentNumber();
        }, 1000);
      } else {
        this.okBtn = 'Подтвердить';
        this.number = 4;
      }
    },

    addGroupInBlackList() {
      if (this.groupName === '') {
        this.infoMsg = 'Укажите имя группы';
        this.$refs.error.classList.toggle('red-color');
        return;
      }

      const key = sessionStorage.getItem('crm-key');
      this.adminApi.value
        .addGroupToBlackList(this.groupName, key)
        .then((resp) => {
          if (resp.error) {
            this.infoMsg = resp.error;
            this.$refs.error.classList.toggle('red-color');
            return;
          }

          this.infoMsg = resp.message;
          this.$refs.error.classList.toggle('green-color');
          setTimeout(() => this.$emit('updateGroups'), 1000);
        })
        .catch((err) => {
          console.log('eerr in added', err);
          this.infoMsg = err;
          this.$refs.error.classList.toggle('red-color');
        });
    },

    resetError() {
      if (this.$refs.error.classList.contains('red-color')) {
        this.$refs.error.classList.toggle('red-color');
        this.infoMsg = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;

  z-index: 100;

  position: absolute;
  top: 0;
  left: 0;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;

    width: 150px;
    height: 40px;

    box-sizing: border-box;

    color: #000000;
    font-size: 20px;

    margin: 0 15px 0;

    background: #dbdcdd;
    border-radius: 10px;

    &:hover {
      background: #45688e7a;
    }
  }

  &__num {
    margin: 0 0 0 10px;
  }

  &__text {
    font-size: 19px;
    padding: 0 15px;
    line-height: 22px;
    margin: 0 20px 25px;

    width: 100%;
    height: 40px;
    box-sizing: border-box;
  }

  &__input {
    width: 100%;
    height: 100%;
    font-size: 20px;
    border-radius: 10px;
    padding: 0 10px;
    box-sizing: border-box;

    &::placeholder {
      font-size: 20px;
      padding: 0 5px;
    }
  }

  &__back {
    background: #22282f3f;
    height: 100%;
    width: 100%;
  }

  &__main {
    width: 450px;
    height: 240px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    position: absolute;
    top: 10%;

    background: #ffffff;
    padding: 40px 0 0;
  }

  &__panel {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto 0 30px;
  }

  &__info {
    width: 94%;
    height: 40px;
    padding: 0 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    margin: 0 40px 15px 40px;
    border-radius: 10px;
  }
}

.red-color {
  background: #a80707;
}
.green-color {
  background: #1e7c0b;
}
</style>
