<template>
  <div class="admin-page">
    <!-- <div class="admin-page__admins">

    </div> -->
    <list-table ref="tableList" class="admin-page__admins"></list-table>

    <aside-menu
      class="settings"
      :class="{ show: getSettingsState }"
      @menuAction="menuAction"
    ></aside-menu>

    <teleport to="body">
      <popup-window
        v-if="showAddWindow"
        @close="toggleModal()"
        @updateGroups="updateGroups"
      ></popup-window>
    </teleport>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import {
  onMounted, ref, provide, computed,
} from 'vue';
import { useRouter } from 'vue-router';
import AsideMenu from '@/components/black-list/AsideMenu.vue';
import ListTable from '@/components/black-list/ListTable.vue';
import AdminAPI from '@/api/adminAPI';
import PopupWindow from '@/components/black-list/PopupWindow.vue';

const tableList = ref(null);
const showAddWindow = ref(false);

const store = useStore();
const getSettingsState = computed(() => store.getters['vkConfiguration/getSettingsState']);

const adminApi = ref({});
provide('adminApi', adminApi);

const deleteGroups = () => {
  const key = sessionStorage.getItem('crm-key');
  const getGroups = tableList.value.getCheckedGroups().map((el) => el.group_id);
  adminApi.value.dellGroupFromBlackList(getGroups, key).then((resp) => {
    if (resp.message) {
      tableList.value.updateGroups();
    }
  });
};

const router = useRouter();
const menuAction = (event) => {
  switch (event) {
    case 'add-bl':
      // addGroupToBlackList(groupName);
      showAddWindow.value = !showAddWindow.value;
      break;
    case 'del-bl':
      deleteGroups();
      break;
    default:
      router.push({ name: 'mega-admins' });
  }
};

const toggleModal = () => {
  showAddWindow.value = !showAddWindow.value;
};

const updateGroups = () => {
  tableList.value.updateGroups();
  toggleModal();
};

onMounted(() => {
  adminApi.value = new AdminAPI();
});
</script>

<style lang="scss" scoped>
.admin-page {
  display: flex;
  justify-content: center;
  align-items: stretch;

  position: relative;

  width: 100%;

  &__admins {
    width: calc(100% - 350px);
    border: 1px solid #03030325;
    position: relative;
    font-size: 22px;

    @media (max-width: 1300px) {
      width: 100%;
    }
  }

  &__icon {
    width: 75px;
    height: 75px;
    border-radius: 20px;

    margin: 0 25px 0 0;
  }
}

.settings {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: auto;
  padding: 0 12px;

  flex-basis: 350px;
  max-width: 350px;
  min-width: 350px;

  background-color: #f7f7f7;
  border-left: 1px solid #d8dfea;

  box-sizing: border-box;

  @media (max-width: 1300px) {
    position: absolute;
    top: 1px;
    right: 0;
    visibility: hidden;
    width: 50%;
    max-width: 350px;
    height: auto;
    min-height: 100%;
    padding: 10px 0 0 0;
  }
}

.show {
  visibility: visible;
}
</style>
